<template>
  <div class="contentWrap">
    <miaHeader />
    <div class="contentbox">
      <div class="content">
        <div class="tab_title">系统注册</div>
        <div class="form">
          <div v-loading="loading" v-if="!registerFlag">
            <el-form
              class="sumbitForm"
              ref="form"
              :model="form"
              :rules="rules"
              label-width="150px"
            >
              <el-row>
                <el-col :span="11">
                  <el-form-item label="公司/商户名称" prop="name">
                    <el-input
                      v-model="form.name"
                      placeholder="填写营业执照上的名称"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="类型" prop="type">
                    <el-select
                      v-model="form.type"
                      style="width: 100%"
                      filterable
                      clearable
                      placeholder="请选择类型"
                    >
                      <el-option
                        v-for="(option, optionIndex) in typeSelectList"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="限制农药经营许可证" prop="limitPic">
                    <el-select
                      v-model="form.limitPic"
                      style="width: 100%"
                      filterable
                      clearable
                      @change="ChangeHisLimit"
                      placeholder="请选择是否持有限制农药经营许可证"
                    >
                      <el-option
                        v-for="(option, optionIndex) in limitSelectList"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="限用农药经营模式" prop="businessModel">
                    <el-select
                      v-model="form.businessModel"
                      style="width: 100%"
                      filterable
                      clearable
                      placeholder="限用农药经营模式"
                    >
                      <el-option
                        v-for="(option, optionIndex) in businessModelSelectList"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                      />
                    </el-select>
                  </el-form-item>
                  <SimpleForm
                    ref="imageForm"
                    :form="form"
                    :rules="rules"
                    :formFields="formFields"
                    lableWidth="150px"
                    :isShowCancel="false"
                    :isShowSave="false"
                    @imgUploadSuc="imageSuccess"
                    @imageRemove="imageRemove"
                  />
                </el-col>
                <el-col :span="2">
                  <div class="tip"></div>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="联系人" prop="master">
                    <el-input
                      v-model="form.master"
                      placeholder="请填写联系人姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="地址" prop="address">
                    <v-distpicker @selected="selectcity"></v-distpicker>
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入详细地址"
                      v-model="form.address"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="登录手机号码" prop="contact">
                    <div class="verificationInput">
                      <el-input
                        v-model="form.contact"
                        placeholder="请填写手机号码"
                      ></el-input>
                      <el-button
                        slot="verificationButton"
                        class="sendCode"
                        type="text"
                        :disabled="!ClickSendFlag"
                        @click="sendCode"
                        >{{ verificationText }}</el-button
                      >
                    </div>
                  </el-form-item>
                  <el-form-item v-if="isShowCaptcha">
                    <SmartCaptcha
                      ref="captcha"
                      scene="ic_login"
                      width="100%"
                      @success="smartCaptchaSuccess"
                    />
                  </el-form-item>
                  <el-form-item label="验证码" prop="tokenCode">
                    <el-input
                      v-model="form.tokenCode"
                      placeholder="请填写4位短信验证码"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="registerTip">
              <div>
                提交注册即代表同意
                <el-button type="text">《用户协议》</el-button>
              </div>
              <div class="sumbitBotton" @click="SubmitForm">提交注册</div>
            </div>
          </div>
          <div v-else>
            <div class="successWrap">
              <div class="success_logo">
                <div class="logo"></div>
                <div class="success_text">注册成功！</div>
              </div>
              <div class="success_content">
                <div class="left">
                  <p>
                    店铺名称：<span>{{ successFrom.name }}</span>
                  </p>
                  <p>
                    店铺编码：<span>{{ successFrom.code }}</span>
                  </p>
                </div>
                <div class="right">
                  <p>
                    <el-button type="primary" round @click="backLogin"
                      >返回登录</el-button
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <miaFooter />
  </div>
</template>
<script>
import { GetVerificationCode, registerShop } from "@/api/common.js";
import SmartCaptcha from "@/components/smartCaptcha/index.vue";
import VDistpicker from "v-distpicker";
export default {
  components: {
    SmartCaptcha,
    VDistpicker,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
          this.ClickSendFlag = true;
        } else {
          callback(new Error("请输入正确的手机号"));
          this.ClickSendFlag = false;
          this.isShowCaptcha = false;
        }
      } else {
        callback(new Error("请填写手机号码，用于登录系统"));
        this.ClickSendFlag = false;
        this.isShowCaptcha = false;
      }
    };
    return {
      // 注册表单
      form: {
        master: "",
        contact: "",
        tokenCode: "",
        tokenId: "",
        name: "",
        type: 0,
        provinceId: "",
        cityId: "",
        areaId: "",
        province: "",
        city: "",
        area: "",
        address: "",
        limitPic: 1,
        businessModel: 0,
        licensePic: "",
      },
      // 加载动画
      loading: false,
      // 选择地址
      cityAddress: null,
      // 发送验证码之前显示人机验证
      isShowCaptcha: false,
      // 照片
      licensePic: null,
      // 验证
      valiteCodeData: null,
      icCap: null,
      verificationText: "发送验证码",
      // 注册成功页面
      registerFlag: false,
      // 注册成功后返回的店铺编码
      successFrom: {},
      time: 60,
      ClickSendFlag: false,
      // 计时器
      timepiece: null,
      formFields: [
        {
          name: "营业执照图片",
          field: "licensePic",
          type: "img",
          imageList: [],
          open: true,
        },
      ],
      typeSelectList: [
        { label: "个体工商户", value: 0 },
        { label: "公司", value: 1 },
      ],
      limitSelectList: [
        { label: "是", value: 0 },
        { label: "否", value: 1 },
      ],
      businessModelSelectList: [
        { label: "无", value: 2 },
        { label: "专柜经营", value: 0 },
      ],
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "请填写营业执照上的名称",
            trigger: "blur",
          },
          {
            min: 6,
            max: 24,
            message: "名称长度在 6 到 24 个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择店铺类型", trigger: "change" },
        ],
        limitPic: [
          {
            required: true,
            message: "请选择是否持有农药经营许可证",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "详细地址长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        businessModel: [
          {
            required: true,
            message: "请选择限用农药经营模式",
            trigger: "change",
          },
        ],
        master: [
          { required: true, message: "请填写联系人姓名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "联系人姓名长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        contact: [{ required: true, validator: validatePhone }],
        licensePic: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        tokenCode: [
          { required: true, message: "请填写4位短信验证码", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "验证码格式有误，请重新输入",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    time(val) {
      if (val === 0) {
        this.resetTime();
      }
    },
    cityAddress(value) {
      this.form.province = value.province.value;
      this.form.city = value.city.value;
      this.form.area = value.area.value;
      this.form.provinceId = +value.province.code;
      this.form.cityId = +value.city.code;
      this.form.areaId = +value.area.code;
    },
  },
  beforeDestroy() {
    clearInterval(this.timepiece);
  },
  methods: {
    SubmitForm() {
      let isImage = false;
      let isForm = false;
      this.$refs["imageForm"].$children[0].validate((valid) => {
        if (valid) {
          isImage = true;
        } else {
          isImage = false;
          return;
        }
      });
      this.$refs["form"].validate((valid) => {
        if (valid) {
          isForm = true;
        } else {
          isForm = false;
          return;
        }
      });
      if (isImage && isForm) {
        this.loading = true;
        if (
          this.form.provinceId !== "" &&
          this.form.cityId !== "" &&
          this.form.areaId !== ""
        ) {
          registerShop(this.form).then((res) => {
            if (res.data && res.code === 1) {
              this.$message.success("注册成功");
              this.registerFlag = true;
              this.successFrom = res.data;
              this.loading = false;
            } else {
              this.$message.error(res.msg);
              this.loading = false;
            }
          });
        } else {
          this.$message.error("请选择店铺所在的省市区");
          this.loading = false;
        }
      }
    },
    // 选择地址
    selectcity(data) {
      this.cityAddress = JSON.parse(JSON.stringify(data));
    },
    // 选择是否持有许可证
    ChangeHisLimit(value) {
      // 仅当[限用农药经营许可证]为[是]时 更改选项
      if (!value) {
        this.businessModelSelectList = [
          { label: "专柜经营", value: 0 },
          { label: "定点经营", value: 1 },
        ];
      } else {
        this.businessModelSelectList = [
          { label: "无", value: 2 },
          { label: "专柜经营", value: 0 },
        ];
      }
      this.form.businessModel = "";
      setTimeout(() => {
        this.$refs["form"].clearValidate(["businessModel"]);
      }, 30);
    },
    // 发送验证码
    sendCode() {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (reg.test(this.form.contact)) {
        this.isShowCaptcha = true;
      } else {
        this.isShowCaptcha = false;
        this.ClickSendFlag = false;
        return;
      }
    },
    // 人机验证
    smartCaptchaSuccess(data) {
      this.valiteCodeData = data.data;
      this.icCap = data.control;
      // 验证成功 发生验证码
      const form = { mobile: this.form.contact, type: 2 };
      const result = { ...form, ...this.valiteCodeData };
      this.ClickSendFlag = false;
      GetVerificationCode(result).then((res) => {
        if (res.code === 1 && res.data) {
          this.form.tokenId = res.data;
          this.$message.success("验证码发送成功，请注意查收");
          this.ClickSendFlag = false;
          this.verificationText = this.time + "s后可重新发送";
          this.timepiece = setInterval(this.changeTime, 1000);
        } else {
          this.resetTime();
          this.$message.error(res.msg);
        }
      });
      this.isShowCaptcha = false;
    },
    changeTime() {
      this.time -= 1;
      this.verificationText = this.time + "s后可重新发送";
    },
    // 重置计时器
    resetTime() {
      clearInterval(this.timepiece);
      this.verificationText = "重新发送";
      this.time = 60;
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (reg.test(this.form.contact)) {
        this.ClickSendFlag = true;
      } else {
        this.ClickSendFlag = false;
      }
    },
    // 上传图片
    imageSuccess(file, list, index, field) {
      this.form[field] = file.response.url;
      this.formFields[index].imageList = list;
      if (field === "licensePic") {
        this.licensePic = list;
      }
    },
    // 图片移除
    imageRemove(field, index, n) {
      const arr = JSON.parse(JSON.stringify(this.formFields));
      arr[index].imageList.splice(n, 1);
      if (field === "licensePic") {
        this.licensePic.splice(n, 1);
      }
      this.formFields = arr;
      this.form[field] = "";
    },
    // 返回登录
    backLogin() {
      sessionStorage.setItem("registerItem", JSON.stringify(this.successFrom));
      this.$router.push({ name: "Login" });
    },
    // 立即登录
    promptlyLogin() {
      sessionStorage.removeItem("registerItem");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="scss" scoped>
.contentWrap {
  width: 100vw;
  height: 100vh;
  background-color: #f5f8fd;
  .contentbox {
    width: calc(100% - 20%);
    padding: 5% 10% 0%;
    // min-height: calc(100% - 35vh);
    .content {
      width: 90%;
      background: #fff;
      position: relative;
      padding: 3% 5%;
      margin-bottom: 10px;
      .tab_title {
        width: 128px;
        height: 44px;
        // background-image: url("../../assets/image/tab_bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 90%;
        position: absolute;
        left: 0px;
        top: -41px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #308ffa;
        font-size: 16px;
      }
      .form {
        width: 100%;
      }
      .sumbitForm {
        /deep/ .distpicker-address-wrapper {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          flex-wrap: nowrap;
          label {
            // width: 32.5%;
            width: 100%;
            select {
              width: 100%;
            }
          }
          margin-bottom: 20px;
        }
        .tip {
          width: 1px;
          height: 40vh;
          background: #f1f3f9;
          margin: 0 auto;
        }
        /deep/ .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        /deep/ .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        /deep/ .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        /deep/ .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }
      .registerTip {
        width: 100%;
        text-align: center;
        display: inline-flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        div {
          width: 100%;
        }
        .sumbitBotton {
          width: 386px;
          height: 50px;
          line-height: 50px;
          background: #2f8ffa;
          border-radius: 4px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin: 0 auto;
          margin-top: 10px;
        }
      }
    }
    .clearfix {
      text-align: left;
    }
    .successWrap {
      width: 100%;
      min-height: 40vh;
      display: inline-flex;
      flex-wrap: wrap;
      align-content: center;
      margin: 0 auto;
      text-align: center;
      .success_logo {
        width: 100%;
        height: 120px;
        .logo {
          width: 120px;
          height: 100%;
          margin: 0 auto;
          background-image: url("../../assets/image/success_r.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 90%;
        }
        .success_text {
          width: 105px;
          margin: 10px auto;
          text-align: right;
          font-size: 18px;
          color: #333333;
        }
      }
      .success_content {
        width: 90%;
        height: 130px;
        margin: 0 auto;
        margin-top: 80px;
        background: #eff6fe;
        border: 1px solid #d5e0f2;
        border-radius: 4px;
        display: inline-flex;
        .left {
          width: 70%;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
        }
        .right {
          width: 30%;
          display: inline-flex;
          align-items: center;
        }
      }
      p {
        width: 100%;
        padding: 0px 5%;
        text-align: left;
      }
    }
  }
}
.mia-footer {
  position: fixed;
  bottom: 0%;
}
</style>